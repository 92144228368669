import { ApolloError } from '@apollo/client/core'
import { GraphQLError } from 'graphql'

type ApiErrorResponse = {
  data: null
  errors: GraphQLError[]
}

export const isAbortError = (error: unknown): boolean =>
  error instanceof Error && error.name === 'AbortError'

const isApiError = (error: any): error is ApiErrorResponse =>
  'errors' in error && error.errors[0] instanceof GraphQLError

export const isSpecificGraphQLError = (
  error: unknown,
  { matchCode, matchMessage }: { matchCode?: string; matchMessage?: string },
): boolean => {
  if (!error) return false

  let errors

  if (error instanceof ApolloError && error.graphQLErrors?.length) {
    errors = error.graphQLErrors
  } else if (isApiError(error)) {
    errors = error.errors
  }

  if (!errors) return false

  return [
    ...(matchMessage
      ? [!!errors.find(({ message }) => message === matchMessage)]
      : []),
    ...(matchCode
      ? [!!errors.find(({ extensions }) => extensions?.code === matchCode)]
      : []),
  ].every(Boolean)
}
